<template>
  <div>
    <div class="flex mt-4" :class="{ 'has-fields': customFieldValue.length > 0 }">
      <vs-button v-if="customFieldsList.length == 0" flat v-round class="only-border-btn" size="medium" @click="showCreatePopup">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12 4C12.5523 4 13 4.44772 13 5V19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19V5C11 4.44772 11.4477 4 12 4Z" fill="#0F67F4"></path>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M4 12C4 11.4477 4.44772 11 5 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H5C4.44772 13 4 12.5523 4 12Z" fill="#0F67F4"></path>
        </svg>
        Create data field
      </vs-button>
      <vs-button v-else flat v-round class="only-border-btn float-right mb-8" size="medium" @click="showCreatePopup">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12 4C12.5523 4 13 4.44772 13 5V19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19V5C11 4.44772 11.4477 4 12 4Z" fill="#0F67F4"></path>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M4 12C4 11.4477 4.44772 11 5 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H5C4.44772 13 4 12.5523 4 12Z" fill="#0F67F4"></path>
        </svg>
        Create data field
      </vs-button>
    </div>

    <vs-row class="grid grid-cols-3 grid-flow-row gap-8">
      <div class="customFieldCard" v-for="(customField, indextr) in customFieldsList" :key="indextr">
        <div class="customFieldCard__header">
          <div class="flex justify-between">
            <vs-card class="blueCard mb-0">
              <span class="text-white flex items-center px-2 py-0.5">
                <img :src="handleFieldImage(customField.dataType, 'src')" :alt="handleFieldImage(customField.dataType, 'alt')" />
                <span class="uppercase text-white ml-2">
                  {{ customField.dataType }}
                </span>
              </span>
            </vs-card>
            <a @click="openEditGroup(customField, indextr)" class="pl-5"><img :src="edit" alt="edit" /></a>
          </div>
          <div class="w-full">
            <h1 class="font-medium text-xl mt-4">{{ customField.label }}</h1>
            <span class="customFieldId">ID: {{ customField.onboardingFieldId }}</span>
          </div>
        </div>
        <div class="p-6">
          <div v-if="type==='business-ownership'">
            <div class="text-dark-blue text-base mb-2">Appears for</div>
            <p>{{getApprearsText(customField.entity)}}</p>
            <hr class="line-hr my-6"/>
          </div>
          <vs-checkbox class="mx-0 text-base checkbox-space" v-model="customField.isRequired" :vs-value="true">
            Field is required
          </vs-checkbox>
        </div>
      </div>
    </vs-row>

    <!-- Delete  -->
    <vs-popup id="delete-custom-field" class="" title="Delete this field?" :active.sync="deletePopupActive">
      <p class="text-sm">
        Are you sure you want to permanently delete this onboarding data field?
        You can't undo this action.
      </p>
      <div class="w-full flex items-baseline mt-8">
        <vs-button
          flat
          v-round
          class="border-only-btn float-right ml-auto mr-2 text-xs deleteButton"
          @click.prevent="deleteEvent"
          >Delete field
        </vs-button>
        <a href="#" @click="cancelDeleteEvent" class="ml-4"><u>Cancel</u></a>
      </div>
    </vs-popup>

    <vs-popup class="holamundo req-template-options product-field-popup" id="halomundo" :title="`${editPopUp ? 'Edit' : 'Create'} onboarding data field`" :active.sync="popupActivo">
      <form>
        <div class="">
          <div class="grid grid-cols-12 gap-y-2 md:gap-x-6">
            <div class="col-span-6">
                <label class="text-base font-normal mr-4">Field name</label>
                <vs-input class="arrayInput" size="large" v-model="label" name="label" maxlength="35" v-validate="'required'" data-vv-validate-on="blur"/>
              <span class="text-danger text-sm" v-show="errors.has('lebel')">
                {{ errors.first("lebel") }}
              </span>
            </div>
            <div class="col-span-6">
              <label class="text-base font-normal mr-4">Tooltip text</label>
              <vs-textarea counter="250" maxlength="250" class="custom-textarea" v-model="tooltipText" :id="`custom-textarea-${id}`" @input="auto_grow('custom-textarea-'+id)" placeholder="Optional"/>
            </div>
          </div>
        </div>
        <div class="mt-10 mb-4">
          <h1 class="text-base font-normal">Field type</h1>
        </div>
        <!-- radio button first row starts here -->
        <vs-row class="product-field-grid mb-8" style="max-width: 831px">
          <!-- text -->
          <div class="radioButtonCard w-min">
            <div class="radio-card" @click="activate('text')" :class="{ active: typeForCss == 'text' }">
              <vs-row>
                <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label">
                  Text
                </vs-col>
                <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2">
                  <vs-radio v-model="dataType" vs-value="text" vs-name="type">
                  </vs-radio>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <div class="pt-1 pb-2">
                    <div class="radio-info">
                      Enter any combination of letters & numbers and special characters
                    </div>
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </div>

          <!-- number -->
          <div class="radioButtonCard w-min">
            <div class="radio-card" @click="activate('number')" :class="{ active: typeForCss == 'number' }">
              <vs-row>
                <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label">
                  Number
                </vs-col>
                <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2">
                  <vs-radio v-model="dataType" vs-value="number" vs-name="type">
                  </vs-radio>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <div class="pt-1 pb-2">
                    <div class="radio-info">
                      Enter value made up of numbers only (decimals allowed)
                    </div>
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </div>

          <!-- currency -->
          <div class="radioButtonCard w-min">
            <div class="radio-card" @click="activate('currency')" :class="{ active: typeForCss == 'currency' }">
              <vs-row>
                <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label">
                  Currency
                </vs-col>
                <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2">
                  <vs-radio v-model="dataType" vs-value="currency" vs-name="type"></vs-radio>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <div class="pt-1 pb-2">
                    <div class="radio-info">Enter numerical, two decimal amount i.e. dollar amount</div>
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </div>

          <!-- date -->
          <div class="radioButtonCard w-min">
            <div class="radio-card" @click="activate('date')" :class="{ active: typeForCss == 'date' }">
              <vs-row>
                <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label">
                  Date
                </vs-col>
                <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2">
                  <vs-radio v-model="dataType" vs-value="date" vs-name="type">
                  </vs-radio>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <div class="pt-1 pb-2">
                    <div class="radio-info">Enter date in DD/MM/YYYY format</div>
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </div>

          <!-- dropdown -->
          <div class="radioButtonCard w-min">
            <div class="radio-card" @click="activate('dropdown')" :class="{ active: typeForCss == 'dropdown' }">
              <vs-row>
                <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label">Dropdown</vs-col>
                <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2">
                  <vs-radio v-model="dataType" vs-value="dropdown" vs-name="type"></vs-radio>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <div class="pt-1 pb-2">
                    <div class="radio-info">Select a value from a pre-defined list of options</div>
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </div>

          <!-- file upload -->
          <div class="radioButtonCard w-min">
            <div class="radio-card" @click="activate('file')" :class="{ active: typeForCss == 'file' }">
              <vs-row>
                <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label">File Upload</vs-col>
                <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2">
                  <vs-radio v-model="dataType" vs-value="file" vs-name="type"></vs-radio>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <div class="pt-1 pb-2">
                    <div class="radio-info">Upload and submit a PDF file</div>
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </div>
        </vs-row>
        <!-- radio button second row ends here -->

        <div v-if="dataType == 'dropdown'" class="mb-8">
          <div class="mt-5 mb-8">
            <h3 class="font-normal text-xl">Configure dropdown</h3>
            <hr class="mt-3 mb-2" />
          </div>
          <vs-row>
            <vs-col vs-w="6">
              <p class="mb-4">
                Add the options you want to appear in the dropdown.
              </p>
              <!-- array form starts here -->
              <div class="configureDropdown">
                <vs-row class="mb-4" v-for="(m, index) in dropdownOptions" :key="index">
                  <vs-col vs-w="3" vs-xs="12" class="pr-6 w-4/5">
                    <div class="arrayInput">
                      <vs-input
                        class="w-full"
                        size="large"
                        v-validate="'required'"
                        v-model="dropdownOptions[index]"
                        :name="`option[${index}]`"
                        data-vv-scope="dropDownScope"
                        data-vv-as="dropdown value"
                      />
                      <span class="text-danger text-sm pt-3" v-if="errors.has(`dropDownScope.option[${index}]`)">
                        {{ errors.first(`dropDownScope.option[${index}]`) }}
                      </span>
                    </div>
                  </vs-col>
                  <vs-col vs-w="3" vs-xs="12" class="flex items-end justify-center w-1/5">
                    <div>
                      <a class="flex" @click="deleteDropdownField(m, index)">
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2.5 5.5H4.16667H17.5" stroke="#828282" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          <path
                            d="M6.6665 5.50002V3.83335C6.6665 3.39133 6.8421 2.9674 7.15466 2.65484C7.46722 2.34228 7.89114 2.16669 8.33317 2.16669H11.6665C12.1085 2.16669 12.5325 2.34228 12.845 2.65484C13.1576 2.9674 13.3332 3.39133 13.3332 3.83335V5.50002M15.8332 5.50002V17.1667C15.8332 17.6087 15.6576 18.0326 15.345 18.3452C15.0325 18.6578 14.6085 18.8334 14.1665 18.8334H5.83317C5.39114 18.8334 4.96722 18.6578 4.65466 18.3452C4.3421 18.0326 4.1665 17.6087 4.1665 17.1667V5.50002H15.8332Z"
                            stroke="#828282"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8.3335 9.66669V14.6667"
                            stroke="#828282"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M11.6665 9.66669V14.6667"
                            stroke="#828282"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </a>
                    </div>
                  </vs-col>
                </vs-row>
                <vs-button v-round size="medium" @click="addDropdownOptions()" class="mt-5 only-border-btn addDropdownOptions">
                  <plus-icon size="1.5x" class="button-icon"></plus-icon>Add
                </vs-button>
              </div>

              <!-- array form ends here -->
            </vs-col>
            <vs-col vs-w="6" style="height: fit-content">
              <h3 class="text-xl font-normal">Preview</h3>
              <!-- <hr class="line-hr mb-8 w-full" /> -->
              <vs-card class="previewCard">
                <vs-row v-if="dataType == 'dropdown'" class="w-full flex flex-col">
                  <label for="dropdown" class="previewLabel font-light">{{ label }}</label>
                  <select class="w-full" name="selectOption" id="dropdown" style="border: none; background: none; border-bottom: 1px solid #bdbdbd;">
                    <option v-for="(selectOption, index) in dropdownOptions" :key="index" :value="selectOption">
                      {{ selectOption }}
                    </option>
                  </select>
                </vs-row>
              </vs-card>
            </vs-col>
          </vs-row>
        </div>
      </form>

      <!-- preview area starts here-->
      <div class="pt-6" v-if="dataType !== 'address' && dataType !== 'dropdown'">
        <h3 class="text-xl font-normal mb-2">Preview</h3>
        <hr class="line-hr" />
        <vs-card class="previewCard" style="">
          <vs-row v-if="dataType != 'dropdown'" class="w-full flex flex-col">
            <label class="previewLabel mb-0 font-light">{{ label }} </label>
            <div class="flex">
              <span class="text-base flex items-end" v-if="dataType == 'currency'">$</span>
              <input type="file" class="hidden" v-if="dataType == 'file'" ref="uploadImgInput" accept="image/png" />
              <vs-button v-if="dataType == 'file'" v-round size="medium" @click="$refs.uploadImgInput.click()" class="only-border-btn mt-0" style="width: min-content">
                Upload file
              </vs-button>
              <vs-input v-else :type="dataType" class="previewInput" />
            </div>
          </vs-row>
        </vs-card>
      </div>
      <!-- preview area ends here-->
      <div class="button-section flex items-center mt-8 md:mt-12 pt-2 mb-0">
        <vs-button v-if="editPopUp" size="large" style="width: 200px; margin-right: 3rem" @click="deleteCustomField()" v-round>Delete Field</vs-button>
        <vs-button :disabled="buttonDisabled" size="large" @click="saveCustomField()" v-round>{{ editPopUp ? "Save" : "Create field" }}</vs-button>
        <div class="mid-blue ml-6 cursor-pointer" @click="onCancel">
          <u>Cancel</u>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import _ from "lodash";
import { Edit2Icon, Trash2Icon, PlusIcon } from "vue-feather-icons";
import { Validator } from "vee-validate";

const deleteicon = require("@/assets/images/delete.png");
const edit = require("@/assets/images/edit.png");
const text = require("@/assets/images/icons/text.png");
const currency = require("@/assets/images/icons/currency.png");
const fileupload = require("@/assets/images/icons/fileupload.png");
const dropdown = require("@/assets/images/icons/dropdown.png");
const datepicker = require("@/assets/images/icons/datepicker.png");
const number = require("@/assets/images/icons/number.png");
const dictionary = {
  en: {
    attributes: {
      label: "field name",
    },
  },
};
Validator.localize(dictionary);

export default {
  name: "customField",
  components: { Edit2Icon, Trash2Icon, PlusIcon },
  props: ["customFieldValue", "type"],
  data() {
    return {
      edit: require("@/assets/images/edit.png"),
      text: require("@/assets/images/icons/text.png"),
      currency: require("@/assets/images/icons/currency.png"),
      fileupload: require("@/assets/images/icons/fileupload.png"),
      dropdown: require("@/assets/images/icons/dropdown.png"),
      datepicker: require("@/assets/images/icons/datepicker.png"),
      number: require("@/assets/images/icons/number.png"),
      value: {},
      option: {},
      customFieldsList: [],
      idForEdit: "",
      typeForCss: "text",
      isChecked: false,
      label: "",
      tooltipText: "",
      dropdownOptions: [],
      dropdownField: "",
      dataType: "text",
      onboardingFieldId: "",
      popupActivo: false,
      isRequired: false,
      editPopUp: false,
      deletePopupActive: false,
      id: Math.floor(Math.random() * 100),
    };
  },

  computed: {
    validateForm() {
      return !this.errors.any();
    },
    buttonDisabled() {
      this.isChecked = this.atLeastOneChecked();

      if (this.label && this.isChecked) {
        if (this.dataType =="dropdown") {
          return this.dropdownOptions.length > 0 && this.dropdownOptions[0].length > 1 ? false : true;
        }

        return false;
      }
      return true;
    },
  },

  beforeUpdate() {
    this.customFieldsList = this.customFieldValue;
  },

  mounted() {
    this.customFieldsList = this.customFieldValue;
  },
  watch:{
    popupActivo() {
      setTimeout(() => {
        this.auto_grow('custom-textarea-'+this.id);
      }, 100);
    },
  },
  methods: {
    ...mapActions("customField", ["createCustomField"]),

    handleFieldImage(dataType, returnType) {
      const source = {
        date: this.datepicker,
        text: this.text,
        number: this.number,
        currency: this.currency,
        dropdown: this.dropdown,
      };

      const alternatives = {
        date: "date",
        text: "text",
        number: "number",
        currency: "currency",
        dropdown: "dropdown",
      };

      if (returnType === "src") {
        return source[dataType];
      }

      if (returnType === "alt") {
        return alternatives[dataType];
      }
    },

    getAddressData: function (addressData, placeResultData, _id) {
      this.$emit("changeAddress", addressData, placeResultData, _id);
    },

    showCreatePopup() {
      this.clearFields();
      this.popupActivo = true;
      this.typeForCss = "text";
      this.id = Math.floor(Math.random() * 10);
    },
    atLeastOneChecked() {
      return true;
    },

    activate(el) {
      this.typeForCss = el;
      this.dataType = el;
    },

    addDropdownOptions() {
      this.$validator.validateAll("dropDownScope").then((result) => {
        if (result) {
          this.dropdownOptions.push("");
        }
      }).catch((ex) => {});
    },

    // delete
    deleteDropdownField(detail, key) {
      this.dropdownOptions.splice(key, 1);
    },

    onCancel() {
      this.popupActivo = false;
      this.label = "";
      this.tooltipText = "";
      this.dataType = "";
      this.dropdownOptions = [];
      this.clearFields();
    },

    showMessage(title = "Successful", message = "Task completed", color = "success", icon = "icon-check-circle") {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },

    openEditGroup(selectedCustomField) {
      this.popupActivo = true;
      this.editPopUp = true;
      this.typeForCss = selectedCustomField.dataType;
      this.idForEdit = selectedCustomField._id;
      this.onboardingFieldId = selectedCustomField.onboardingFieldId;
      this.label = selectedCustomField.label;
      this.tooltipText = selectedCustomField.tooltipText || '';
      this.dataType = selectedCustomField.dataType;
      this.dropdownOptions = selectedCustomField.dropdownOptions || [];
      this.option = this.dropdownOptions;
      this.id = Math.floor(Math.random() * 100);
    },

    deleteCustomField() {
      this.deletePopupActive = true;
    },

    clearFields() {
      this.editPopUp = false;
      this.label = "";
      this.tooltipText = "";
      this.onboardingFieldId = "";
      this.dataType = "text";
      this.dropdownOptions = [];
      this.option = {};

      for (var key in this.customField) {
        this.customField[key] = false;
      }

      this.id = Math.floor(Math.random() * 100);
    },

    async saveCustomField() {
      try {
        const isValid = await this.$validator.validateAll("dropDownScope");

        if (!isValid) {
          return false;
        }

        let firstBits = Math.floor(1000 + Math.random() * 9000);
        let secondBits = Math.floor(1000 + Math.random() * 9000);
        let fieldId = this.onboardingFieldId ? this.onboardingFieldId : `OF-${firstBits}-${secondBits}`;

        const newField = {
          _id: fieldId,
          label: this.label,
          tooltipText: this.tooltipText,
          onboardingFieldId: fieldId,
          dataType: this.dataType,
          isRequired: this.isRequired,
        };

        if (this.type === "business-ownership") {
          newField.entity = ["company"];
        }

        if (this.dataType == "dropdown") {
          newField["dropdownOptions"] = Object.entries(this.dropdownOptions).map((el) => el[1]);
        }

        if (this.editPopUp == false) {
          this.customFieldsList.push(newField);
        }

        if (this.editPopUp == true) {
          let objIndex = this.customFieldsList.findIndex((obj) => obj._id == this.idForEdit);

          // save all checkboxes values
          Object.assign(this.customFieldsList[objIndex], _.omit(newField, ["_id"]));

          if (this.customFieldsList[objIndex].dataType == "dropdown") {
            this.customFieldsList[objIndex].dropdownOptions = Object.entries(this.option).map((el) => el[1]);
          }
        }

        this.$emit("savedCustomField", this.customFieldsList);
        this.popupActivo = false;
        this.label = "";
        this.tooltipText = "";
        this.dataType = "";
        this.dropdownOptions = [];
        this.showMessage("Successful", "Custom field has been created successfully.", "success", "icon-check-circle");
      } catch (ex) {}
    },

    deleteEvent() {
      let objIndex = this.customFieldsList.findIndex((obj) => obj._id == this.idForEdit);
      this.customFieldsList.splice(objIndex, 1);
      this.popupActivo = false;
      this.editPopUp = false;
      this.label = "";
      this.tooltipText = "";
      this.dataType = "";
      this.dropdownOptions = [];
      this.deletePopupActive = false;
      this.$emit("savedCustomField", this.customFieldsList);
    },

    cancelDeleteEvent() {
      this.deletePopupActive = false;
    },

    auto_grow(elem) {
      let element = document.getElementById(elem);

      if(!element) {
        return;
      }

      element.style.height = "5px";
      element.style.height = (element.scrollHeight)+"px";
    },

    getApprearsText(val) {
      if (!val.length) {
        return;
      }

      let text = "";

      if (val.length > 1) {
        text = "Individual (sole trader) & Company entities";
      } else {
        text = val[0] === "individual" ? "Individual (sole trader)" : "Company entities";
      }

      return text;
    }
  },
};
</script>
